<template>
  <div>
    <a-card title="点检保养计划">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" @change="search" />
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-select v-model="searchForm.type" placeholder="类型" allowClear style="width: 100%" @change="search">
            <a-select-option value="inspection">设备点检</a-select-option>
            <a-select-option value="maintenance">设备保养</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-select v-model="searchForm.status" placeholder="状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="editing">编辑中</a-select-option>
            <a-select-option value="completed">已完成</a-select-option>
            <a-select-option value="closed">已关闭</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 180px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true"
            >新增点检保养计划</a-button
          >
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" @destroy="destroy" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { maintenancePlanList } from "@/api/equipment";
import moment from "moment";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "编号",
          dataIndex: "number",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "类型",
          dataIndex: "type_display",
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (value) => {
            if (value === "editing") {
              return <a-badge status="processing" text="编辑中" />;
            } else if (value === "completed") {
              return <a-badge status="success" text="已完成" />;
            } else if (value === "closed") {
              return <a-badge status="default" text="已关闭" />;
            }
          },
        },
        {
          title: "频率",
          dataIndex: "frequency",
        },
        {
          title: "频率单位",
          dataIndex: "frequency_unit_display",
        },
        {
          title: "开始日期",
          dataIndex: "start_date",
        },
        {
          title: "结束日期",
          dataIndex: "end_date",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          dataIndex: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.dataLoading = true;
      maintenancePlanList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
